import React from "react"
import { Link } from "gatsby"
import TestimonialSection from "../components/testimonials-section"
import Collapsible from 'react-collapsible';
import Layout from "../components/layout"
import Hero from "../components/hero"
import Cta from "../components/cta"
import Seo from "../components/seo"
import ChevronDown from "../components/svg/chevronDown"
import ArrowRight from "../components/svg/arrowRight"

const DjangoPage = () => (
    <Layout>
        <Seo 
            title="Python Django developers" 
            description="Experienced Python Django developers in Sydney and Canberra. We develop web applications using Python and sometimes Django too."
            pathname="/django-developer-sydney-canberra/"
            serviceType="Django application development"
        />

        <Hero
            h1="Django Developer"  
            h2="We're highly experienced Python / Django developers in Sydney and Canberra"
        />
        
        <section className="bg-purple with-pad-sm">
            <div className="container">
                <div className="row">
                    <div className="below-hero"
                        data-sal="slide-right" 
                        data-sal-easing="ease-in-out"
                        data-sal-duration="400"
                    >
                        <div className="below-hero-widget">
                            <span className="widget-heading">10+</span>
                            <p>We have more than 10 years experience using Django for websites (CMS) and applications.</p>
                        </div>
                        <div className="below-hero-widget">
                            <span className="widget-heading">15+</span>
                            <p>We have 15 years experience using Python for backend development and data science.</p>
                        </div>
                        <div className="below-hero-widget">
                            <span className="widget-heading">Local</span>
                            <p>All of our Python / Django development work is done onshore in Australia.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="section-pad" id="anchor">
            <div className="container">
                <div className="row">
                    <div className="cols">
                        <div className="col-26">
                            <h3
                            >Web development</h3>
                        </div>

                        <div className="col-67 long-text"
                            data-sal="slide-up" 
                            data-sal-easing="ease-in-out"
                            data-sal-duration="400"
                        >
                            <p>
                                Since Launch Lab started in 2014 we've developed startups, content management systems, backends for mobile apps and more using the Django web development framework.
                            </p>
                            <p>
                                While we continue to use <Link to="python-developer/">Python</Link> in almost all products we develop, including <Link to="/machine-learning-developers-sydney/">machine learning projects</Link>, when it comes to Django we'll use it if the product is a good fit.
                            </p>
                            <p>
                                If not, we'll suggest a better fit. For example, we now build most of our startups without Django and instead leverage AWS CDK.   
                            </p>
                            <p>
                                If you have an existing Django project that you need support or further development on we'd love to chat. If you've been told to develop your product using Django and are looking for developers, let's chat. We can discuss the requirements and the most suitable stack.  
                            </p>
                            <div className="btn-row">
                                <Link to="/contact/" className="btn btn-lg btn-purple">
                                    <span className="flex flex-ac">
                                        Contact us
                                        <ArrowRight />
                                    </span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="section-pad bg-black">
            <div className="container">
                <div className="row">
                    <div className="cols">
                        <div className="col-26">
                            <h5 className="title">
                                FAQs
                            </h5>
                        </div>
                        <div className="col-67">
                            <Collapsible 
                                transitionTime={100}
                                trigger={
                                <>
                                    Are you Australian Python developers? <ChevronDown />
                                </>
                                }
                            >
                                <p>Yes. All of our Python development work is done by a senior web development team in Sydney and Canberra.</p>
                            </Collapsible>
                            <Collapsible 
                                transitionTime={100}
                                trigger={
                                <>
                                    Do you only use Python and Django? <ChevronDown />
                                </>
                                }
                            >
                                <p>No. It depends on the project. Currently the majority of our application development work is done using Python but without Django. However, we continue to support startup applications and large CMS' that use Django.</p>
                                <p>View all of our <Link to="/services/">web development services</Link>.</p>
                            </Collapsible>
                            <Collapsible 
                                transitionTime={100}
                                trigger={
                                <>
                                    Do you offer Django CMS development? <ChevronDown />
                                </>
                                }
                            >
                                <p>We currently support projects that use the default Django Admin as a CMS as well as corporate projects that make use of FeinCMS.</p>
                                <p>If you are looking for a new website and new CMS we're more likely to recommend a modern <Link to="/headless-cms/">headless CMS</Link> these days using Gatsby and Contentful or Storyblok.</p>
                            </Collapsible>
                            <Collapsible 
                                transitionTime={100}
                                trigger={
                                <>
                                    Should I develop my startup using Django? <ChevronDown />
                                </>
                                }
                            >
                                <p>Maybe. It really depends on the features and requirements of the startup product. If you are considering using Python / Django for your startup, and are looking to outsource your development to <Link to="/startup-web-application-development/">Australian startup developers</Link> get in touch and we can discuss the options and the best path forward.</p>
                            </Collapsible>
                        </div>
                    </div>
 
                </div>
            </div>
        </section>

        <TestimonialSection />

        <Cta 
            text="Get a Django web development quote from a highly experienced Australian Python development team"
        />
    </Layout>
)

export default DjangoPage